<template>
    <div class="page" id="reorganize">
        <el-form class="query-form" size="small" @keyup.enter.native="getDataList(1,1)" inline ref="inputForm" :model="inputForm" label-width="100px">
            <el-form-item label="启动状态" prop="modelState">
                <el-select v-model="inputForm.modelState"
                           placeholder="请选择启动状态"
                           style="width: 100%"
                           clearable>
                    <el-option
                            v-for="item in typeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="输入搜索" prop="keyWord">
                <el-input v-model="inputForm.keyWord"
                          placeholder="请输入模型名称(限20字)"
                          maxlength="20" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="getDataList(1,1)"
                           icon="el-icon-search">查询
                </el-button>
                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button type="primary" size="small"
                           v-show="hasPermissionButton(`dyyg:image:modelT:add`)"
                           icon='el-icon-plus' @click="addData(0)">新增
                </el-button>
                <el-button size="small" icon="el-icon-delete" type="danger" @click="deleteDate()"
                           v-show="hasPermissionButton(`dyyg:image:modelT:delete`)">删除
                </el-button>
            </el-form-item>

        </el-form>

        <div class="bg-white">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 274px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="modelName" label="模型名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="modelIdentification" label="模型标识内容"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="modelStartTime" label="资源类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>图片</div>
                    </template>
                </el-table-column>
                <el-table-column prop="modelState" label="启用状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.modelState"
                                :active-value="'0'"
                                :inactive-value="'1'"
                                @change="setEnableState(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`dyyg:image:modelT:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(2, scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:modelT:edit`)">
                            修改
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:modelT:delete`)">
                            删除
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="modelTraining(scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:modelT:training`)">
                            模型训练
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="modelTest(scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:modelT:test`)">
                            模型测试
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <model-add ref="modelAdd" @refreshList="getDataList()"></model-add>
        <model-training-pop ref="modelTrainingPop"></model-training-pop>
        <model-test ref="modelTest"></model-test>
    </div>
</template>

<script>
import ModelAdd from "@/views/modules/dyyg/imageProcessing/modelAdd.vue";
import ModelTrainingPop from "@/views/modules/dyyg/imageProcessing/modelTrainingPop.vue";
import ModelTest from "@/views/modules/dyyg/imageProcessing/modelTest.vue";

export default {
    name: "modelTraining",
    components: {ModelTest, ModelTrainingPop, ModelAdd},
    data() {
        return {
            inputForm: {
                modelState: '',
                keyWord: '',
            },
            typeList: [
                {
                    value: '0',
                    label: '启用',
                },
                {
                    value: '1',
                    label: '禁用',
                },
            ],


            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },

    mounted() {
        this.getDataList()
    },

    methods: {
        //获取列表数据
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true;
            this.$axios(this.api.original.dyygmodelGetDyygModel, {
                ...this.inputForm,
                current: this.current,
                size: this.size,
            }, 'post').then(res => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //启用禁用
        setEnableState(row) {
            this.$axios(this.api.original.dyygmodelUpdateById1, {
                id: row.id,
                modelState: row.modelState,
            }, "put").then((data) => {
                if (data && data.status) {
                    this.$message.success('修改成功')
                    this.search()
                } else {
                    this.$message.error(data.msg)
                }
            });
        },

        //num 0新增 1详情 2修改
        addData(num, row) {
            this.$refs.modelAdd.init(num, row)
        },

        deleteDate(row) {
            if (!row && !this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            let ids
            if (row) {
                ids = [row.id]
            } else {
                ids = this.dataListSelect.map(item => {
                    return item.id
                })
            }
            this.$confirm(`确认删除所选数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.dyygmodelRemoveById, ids, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //模型训练
        modelTraining(row) {
            this.$refs.modelTrainingPop.init(row)
        },

        //模型测试
        modelTest(row) {
            this.$refs.modelTest.init(row)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 2);
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },
    },
}
</script>

<style scoped>
.bg-white {
    overflow-y: auto !important;
}</style>