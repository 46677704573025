<template>
  <el-dialog
      title="模型训练"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <div v-show="pagState == 0" class="zheaug">
      <resume-upload ref="resume-upload" :uploadNum="imgFileNumr" :upFileSize="upFileSize" :allowFiles="imgPattern"
                     @upFileDate="(data)=>getUpData(data,1)" :upDateType="'img'"></resume-upload>
      <div v-show="imgList.length == imgFileNumr" class="zheuag" @click.stop></div>
      <div class="flex_l_c" style="flex-wrap: wrap">
        <div
            v-for="(item,index) in imgList" :key="item.id"
            style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
        >
          <div class="el-icon-error" @click="deleteFile(item,index)"
               style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
          <el-image
              style="width: 100%; height: 110px;"
              :src="item.netUrl"
              :fit="'cover'"
              :preview-src-list="[item.netUrl]"
          >
            <div slot="error" class="image-slot">
              <el-image
                  style="width: 100%; height: 110px;"
                  :src="require('@/assets/img/default.png')"
                  :fit="'cover'">
              </el-image>
            </div>
          </el-image>
          <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
            <div style="font-size: 12px;width: 100%" class="omit">{{ item.fileName }}</div>
          </el-tooltip>
        </div>
      </div>
      <div class="text_center">
        <el-button size="small" @click="handleClose()">取消</el-button>
        <el-button size="small" type="primary" @click="sureData()" v-no-more-click>提交</el-button>
      </div>
    </div>
    <el-result v-show="pagState == 1" icon="success" title="" :subTitle="`已加入${imgList.length}张图片至训练库！`">
      <template slot="extra">
        <el-button size="small" type="primary" @click="handleClose()">完成</el-button>
      </template>
    </el-result>
  </el-dialog>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  name: "modelTrainingPop",
  components: {resumeUpload},
  data() {
    return {
      dialogVisible: false,
      selectData: {},
      pagState: 0,

      inputType: false,
      imgFileNumr: 10,
      imgPattern: ['PNG', 'BMP', 'JPG', 'JPEG', 'GIF'],
      upFileSize: 10,
      imgList: [],
    }
  },

  methods: {
    init(row) {
      this.imgList = []
      this.pagState = 0
      this.selectData = row
      this.dialogVisible = true
    },

    //获取上传文件
    getUpData(data, num) {
      this.imgList = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
    },

    deleteFile(row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.imgList.splice(index, 1)
        this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
      }).catch(() => {
      })
    },

    sureData() {
      if (this.imgList.length == 0) {
        this.$message.error('请上传训练图片')
        return
      }
      //     this.$axios(this.api.original.dyygmodelUpdateById, {
      //         ...setData,
      //         modelResourceList,
      //     }, 'put').then((res) => {
      //         if (res.status) {
      this.pagState++
      //         } else {
      //             this.$message.error(res.msg);
      //         }
      //     })

    },

    handleClose() {
      this.imgList.forEach(item => {
        this.$refs["resume-upload"].deleteFile(item.fileID, null, '1')
      })
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.zheaug {
  position: relative;
}

.zheuag {
  position: absolute;
  background: rgba(192, 200, 217, 0.3);
  z-index: 99;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
}
</style>